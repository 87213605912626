import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import useAsyncEffect from 'use-async-effect';
import { useNavigate } from 'react-router-dom';

import { camelCase } from 'lodash';

import { useSound } from '../../../../../hooks/media';
import { useMytaverse } from '../../../../../providers/MytaverseProvider';
import { useMytaverseEvent } from '../../../providers';
import {
  getErrorNotification,
  useNotification,
} from '../../../../../components/Notification';
import { NOTIFICATION_TYPES } from '../../../../../components/Notification';
import ROUTES from '../../../../../constants/routes';

import { useConference } from '../Dolby';
import { useHandleUEConnection } from './ueWsConnection';

import { setSessionStorageValue } from '../../../../../helpers/sessionStorage';

import EventsService from '../../../../../services/EventsService';

import FollowMeSound from '../../../../../public/sounds/followMe.mp3';

import { MytaverseLogger } from '../../../../../helpers/logger';

import {
  IParticipantPositionMessage,
  IWebSocketConnectionInfo,
  LastWebsocketJsonMessageType,
  PlayerViewUEEnum,
  SpatialType,
  WebsocketAction,
  WebsocketMessage,
} from '../../../../../interfaces/webSocketConnectionInfo';
import { PoiMediaType, SendToBriefcasePropsTypeFull } from '../interfaces';
import { SessionStorage } from '../../../../../constants/storage';
import { IFollowerData } from '../../../../../interfaces/followers';
import { UnknownObjectType } from '../../../../../interfaces';

import { IWebsocketMessageFlag } from '../../../providers/MytaverseEventProvider/interfaces';
import useGoldenTicket from '../GoldenTicket/hooks';
import { useZoom } from '../Zoom/ZoomProvider';

//import { stopCommunicationFromUeTimer } from '../../../../../helpers/websocket';

interface HandleJoinedToRoomProps {
  roomId: string | null;
  participantId: string;
  gameSessionId: string | null;
  region?: string | null;
}

interface HandleLeftRoomProps {
  participantId: string;
}

const {
  ConnectionInfo,
  JoinedToRoom,
  FullscreenSharing,
  LeftRoom,
  JoinedToEvent,
  LeftEvent,
  JoinedToRegion,
  Download_Golden_Ticket,
  LeftRegion,
  ChangeRoomScale,
  BrowserUrlRequest,
  MuteParticipant,
  ShowParticipantInfo,
  SendToBriefcase,
  ChangeParticipantProfile,
  MessageToWebapp,
  Ue5WsConnected,
  Ue5WsDisconnected,
  FirstPersonViewRegion,
  FreePersonViewRegion,
  MuteConference,
  ParticipantPosition,
  StopDolbyScreenSharing,
  FollowPlayer,
  InviteFollowingByAdmin,
  StopFollowingByAdmin,
  CancelInviteFollowingByAdmin,
  AcceptFollowing,
  DisengageFollowing,
  StopFollowing,
  OpenTeleportModal,
  CloseTeleportModal,
  ShowNotification,
  ShowDemoNotification,
  TeleportFinished,
  OwnerTeleport,
  TerminateSession,
  AvatarRequested,
  AvatarReceived,
  AvatarSelect,
  GamecastSessionTerminated,
  UEError,
  UEWarn,
  UEDebug,
  UEInfo,
} = WebsocketAction;

const { ShowControls, HideControls } = WebsocketMessage;

export const useGetWebsocketMsg = () => {
  const prevWebsocketMsgStrRef = useRef('');

  const getWebsocketMsg = (
    websocketMsg: LastWebsocketJsonMessageType,
  ): LastWebsocketJsonMessageType => {
    const websocketMsgStr = JSON.stringify(websocketMsg);

    if (prevWebsocketMsgStrRef.current === websocketMsgStr) {
      return null;
    }

    prevWebsocketMsgStrRef.current = websocketMsgStr;

    return websocketMsg;
  };

  return getWebsocketMsg;
};

// TODO should be split
export const useHandleWebsocketMsg = (
  lastJsonMessage: LastWebsocketJsonMessageType,
) => {
  const loadingScreenStartTimeRef = useRef<number | null>();

  const {
    setWebsocketConnectionInfo,
    sendJSONMessageToWebSocket,
    navigateToEventsPage,
  } = useMytaverse();
  const { handleDolbyWSMessages } = useConference();

  const {
    isJoiningConference,
    handleJoinToConference,
    setExpandedScreenSharing,
    zoomConferenceName,
  } = useZoom();

  const {
    updateParticipant,
    setParticipantState,
    pointsOfInterest,
    setPoiPreviewSrc,
    userFiles,
    setUserFiles,
    setShowControls,
    trackAnalytics,
    shareScreenPublishers,
    shareVideoPublishers,
    currentParticipant,
    setMuted,
    muted,
    setRoomDolbySpatialAudioScale,
    initMessageSended,
    currentRegion,
    setIsTeleporting,
    setShowExitButton,
    setIsHideWebUI,
    currentEvent,
    setShowGenerateImageModal,
  } = useMytaverseEvent();
  const {
    setPreviewingParticipant,
    setDisabledPersonView,
    setParticipantPosition,
    setScreenMediaStreams,
    screenMediaStreams,
    setUEWebcamScreenName,
    setIsFirstPersonView,
    setUEScreenScreenName,
  } = useConference();
  const { downloadTicket: handleDownloadGoldenTicket } = useGoldenTicket();
  const { showNotification, getSuccessNotification } = useNotification();
  const { t: translate } = useTranslation('common');
  const { handleUe5WsConnected, handleUe5WsDisconnected } =
    useHandleUEConnection();

  useAsyncEffect(async () => {
    if (
      currentParticipant &&
      !currentParticipant.roomId &&
      !loadingScreenStartTimeRef.current
    ) {
      await trackAnalytics('LOADING_SCREEN_START', {});
      loadingScreenStartTimeRef.current = new Date().getTime();
    }
  }, [trackAnalytics, currentParticipant]);

  useAsyncEffect(async () => {
    if (
      currentParticipant &&
      currentParticipant.roomId &&
      loadingScreenStartTimeRef.current
    ) {
      await trackAnalytics('LOADING_SCREEN_FINISH', {
        duration: new Date().getTime() - loadingScreenStartTimeRef.current,
      });
      loadingScreenStartTimeRef.current = null;
    }
  }, [currentParticipant, trackAnalytics]);

  const handleJoinedToRoom = useCallback(
    ({ roomId, participantId, gameSessionId }: HandleJoinedToRoomProps) => {
      if (
        !roomId ||
        !participantId ||
        !initMessageSended ||
        (currentParticipant &&
          currentParticipant.participantId === participantId)
      ) {
        if (currentRegion) {
          sendJSONMessageToWebSocket({
            action: WebsocketAction.JoinedToRegion,
            timestamp: Date.now(),
            participantId: participantId,
            region: currentRegion.region,
          });
        }
        return;
      }

      setTimeout(() => {
        MytaverseLogger.log(
          `Participant ${participantId} joined to room ${roomId}`,
        );
        setParticipantState(participantId, {
          roomId,
          region: currentRegion || null,
          regions: [],
          gameSessionId,
        });
      }, 7000);
    },
    [setParticipantState, currentParticipant, initMessageSended, currentRegion],
  );

  const handleLeftRoom = useCallback(
    ({ participantId }: HandleLeftRoomProps) => {
      if (!participantId) {
        return;
      }

      MytaverseLogger.log(`Participant ${participantId} left to room`);

      setParticipantState(participantId, {
        roomId: null,
        region: null,
        regions: [],
        gameSessionId: null,
      });
    },
    [setParticipantState],
  );

  const handleTeleportFinished = useCallback(() => {
    setTimeout(() => {
      //e5WebsocketConnected with timeout
      setIsTeleporting(false);
    }, 6000);
  }, [setIsTeleporting]);

  const handleOwnerTeleport = useCallback(
    (ownerId: string, followerIds: string[]) => {
      if (!currentParticipant || ownerId === currentParticipant.participantId) {
        return;
      }
      if (followerIds.indexOf(currentParticipant.participantId) === -1) {
        return;
      }

      MytaverseLogger.log(`Teleport ownerId: ${ownerId}`);
      setIsTeleporting(true);
    },
    [currentParticipant, setIsTeleporting],
  );

  const handleStopDolbySharing = useCallback(
    (mediaStreamId: string) => {
      setScreenMediaStreams((prev) =>
        prev.filter((s) => s.stream.id !== mediaStreamId),
      );
    },
    [setScreenMediaStreams, screenMediaStreams],
  );

  const handleShowParticipantInfo = useCallback(
    async (participantId = '') => {
      if (!participantId) {
        return;
      }

      const participantProfile = await EventsService.getParticipantProfile(
        participantId,
      );
      if (participantProfile) {
        setPreviewingParticipant(participantProfile);
      }
    },
    [setPreviewingParticipant],
  );

  const handleSendToBriefcase = useCallback(
    ({
      fileName,
      downloadUrl = '',
      mediaType,
    }: SendToBriefcasePropsTypeFull) => {
      if (!fileName || !pointsOfInterest) {
        return;
      }

      setPoiPreviewSrc({
        downloadUrl,
        fileName,
        mediaType,
      });

      const existingFile = userFiles.some(({ name }) => name === fileName);

      if (existingFile) {
        return;
      }

      const file = pointsOfInterest.find(({ name }) => name === fileName);

      if (file) {
        setUserFiles((prev) => Array.from(new Set([file, ...prev])));
      }
    },
    [pointsOfInterest, setPoiPreviewSrc, setUserFiles, userFiles],
  );

  const trackPoiClick = useCallback(
    (fileName: string) => {
      if (!fileName || !pointsOfInterest) {
        return;
      }

      const poi = pointsOfInterest.find(({ name }) => name === fileName);

      if (poi) {
        const {
          roomId,
          data: { id: poiId, url },
          type,
          name,
        } = poi;

        trackAnalytics('POI_CLICK', {
          poi: { poiId, url, name, type, roomId },
        }).then(() => {});
      }
    },
    [pointsOfInterest, trackAnalytics],
  );

  const handleParticipantPositionChanged = useCallback(
    async ({
      timestamp,
      gameSessionId,
      eventId,
      roomId,
      x,
      y,
      z,
      r,
      regionName,
      regionSpatialType,
    }: IParticipantPositionMessage) => {
      if (!currentParticipant || !currentEvent) {
        return;
      }

      const currentRegionName = currentParticipant.region
        ? currentParticipant.region.region
        : '';

      if (!isJoiningConference && currentParticipant.roomId) {
        await handleJoinToConference(
          currentEvent.eventId,
          currentParticipant.roomId,
          regionName,
        );
      }

      if (
        !currentParticipant.roomId ||
        currentParticipant.roomId !== roomId ||
        currentParticipant.gameSessionId !== gameSessionId ||
        currentRegionName !== regionName
      ) {
        setParticipantState(currentParticipant.participantId, {
          eventId,
          roomId,
          region: regionName
            ? {
                timestamp: Date.now(),
                region: regionName,
                state: 'joined',
                regionSpatialType:
                  regionSpatialType && regionSpatialType.length !== 0
                    ? regionSpatialType
                    : SpatialType.SpatialAudio,
              }
            : null,
          regions: [],
          gameSessionId,
        });
      }

      setParticipantPosition({
        timestamp,
        x: Math.trunc(x),
        y: Math.trunc(y),
        z: Math.trunc(z),
        r: Math.trunc(r),
      });
      setSessionStorageValue(SessionStorage.ParticipantPosition, {
        x,
        y,
        z,
        r,
      });
    },
    [
      currentParticipant,
      setParticipantPosition,
      setParticipantState,
      isJoiningConference,
      handleJoinToConference,
      currentEvent,
    ],
  );

  const handleChangeParticipantProfile = useCallback(
    async (participantId: string) => {
      if (!participantId) {
        return;
      }

      const updatedParticipant = await EventsService.getParticipant(
        participantId,
      );

      if (updatedParticipant) {
        updateParticipant(participantId, updatedParticipant);
      }
    },
    [updateParticipant],
  );

  const handleMessageToWebapp = useCallback(
    (message: WebsocketMessage | undefined) => {
      if (message === HideControls) {
        setShowControls(false);
        return;
      }

      if (message === ShowControls) {
        setTimeout(() => {
          setShowControls(true);
        }, 8000);
        return;
      }
    },
    [setShowControls],
  );

  const handleMuteConference = useCallback(
    ({ conferenceId, mutedBy }: { conferenceId: string; mutedBy: string }) => {
      if (
        conferenceId === zoomConferenceName &&
        currentParticipant &&
        mutedBy !== currentParticipant.participantId &&
        !muted
      ) {
        setMuted(true);
        showNotification(
          getSuccessNotification({
            title: translate(
              `notifications.mutedByModerator.${
                !muted ? 'title' : 'unmutedTitle'
              }`,
            ),
            message: translate(
              `notifications.mutedByModerator.${!muted ? 'muted' : 'unmuted'}`,
            ),
          }),
        );
      }
    },
    [currentParticipant, muted, setMuted, showNotification, zoomConferenceName],
  );

  const handleFirstPersonViewRegion = useCallback(() => {
    setDisabledPersonView(true);
  }, [setDisabledPersonView]);

  const handleFreePersonViewRegion = useCallback(() => {
    setDisabledPersonView(false);
  }, [setDisabledPersonView]);

  useEffect(() => {
    if (!lastJsonMessage) {
      return;
    }

    const {
      action,
      timestamp,
      gameSessionId = '',
      eventId = '',
      roomId = '',
      x,
      y,
      z,
      r,
      data,
      message,
      participantId = '',
      fileName = '',
      downloadUrl = '',
      regionSpatialType = SpatialType.SpatialAudio,
      mediaType = '',
      conferenceId,
      mutedBy,
      mediaStreamId,
      playerPosition,
      dolbySpatialAudioScale,
      zoomWebcamScreenId,
      zoomScreenScreenId,
      generateImageScreens,
    } = lastJsonMessage;

    let { region = '', regionName = '' } = lastJsonMessage;

    if (region) {
      region = camelCase(region);
    }

    if (regionName) {
      regionName = camelCase(regionName);
    }

    switch (action) {
      case ConnectionInfo: {
        setWebsocketConnectionInfo(data);
        break;
      }

      case JoinedToRoom: {
        handleJoinedToRoom({ roomId, participantId, region, gameSessionId });
        break;
      }

      case FullscreenSharing: {
        setExpandedScreenSharing(true);
        break;
      }

      case Download_Golden_Ticket: {
        handleDownloadGoldenTicket();
        break;
      }

      case LeftRoom: {
        handleLeftRoom({
          participantId,
        });
        break;
      }

      case TeleportFinished: {
        handleTeleportFinished();
        break;
      }
      case OwnerTeleport: {
        const { ownerId, followerIds } = lastJsonMessage;
        if (ownerId && followerIds?.length) {
          handleOwnerTeleport(ownerId, followerIds);
        }
        break;
      }
      case ParticipantPosition: {
        const messageTimestamp = timestamp ? parseInt(timestamp) : 0;

        handleParticipantPositionChanged({
          timestamp: messageTimestamp,
          gameSessionId: gameSessionId || playerPosition.CurrentGameSessionId,
          eventId: eventId || playerPosition.eventId,
          roomId: roomId || playerPosition.roomId,
          regionName,
          regionSpatialType,
          x: x || playerPosition.x,
          y: y || playerPosition.y,
          z: z || playerPosition.z,
          r: r || playerPosition.r,
        });

        setUEWebcamScreenName(zoomWebcamScreenId || null);
        setUEScreenScreenName(zoomScreenScreenId || null);

        setShowGenerateImageModal(generateImageScreens);

        const {
          showExitButton = false,
          isHideWebUI = false,
          playerView,
        } = lastJsonMessage;

        const updateState = (
          setState: React.Dispatch<React.SetStateAction<IWebsocketMessageFlag>>,
          messageTimestamp: number,
          newFlag: boolean,
        ) => {
          setState((prevState) => {
            return prevState.timestamp > messageTimestamp
              ? { ...prevState }
              : { flag: newFlag, timestamp: messageTimestamp };
          });
        };

        updateState(setShowExitButton, messageTimestamp, showExitButton);
        updateState(setIsHideWebUI, messageTimestamp, isHideWebUI);

        if (playerView) {
          updateState(
            setIsFirstPersonView,
            messageTimestamp,
            playerView === PlayerViewUEEnum.FirstPerson,
          );
        }

        break;
      }
      case ChangeRoomScale: {
        if (roomId && dolbySpatialAudioScale) {
          setRoomDolbySpatialAudioScale(roomId, dolbySpatialAudioScale);
        }

        break;
      }
      case ShowParticipantInfo: {
        handleShowParticipantInfo(participantId);
        break;
      }
      case SendToBriefcase: {
        if (!mediaType) {
          return;
        }

        handleSendToBriefcase({
          fileName,
          downloadUrl,
          mediaType:
            // @ts-ignore
            mediaType === 'websiteUrl'
              ? PoiMediaType.WEB_BROWSER
              : (mediaType.toUpperCase() as PoiMediaType),
        });
        trackPoiClick(fileName);

        break;
      }
      case ChangeParticipantProfile: {
        handleChangeParticipantProfile(participantId);
        break;
      }
      case MessageToWebapp: {
        handleMessageToWebapp(message);
        break;
      }

      case Ue5WsConnected: {
        handleUe5WsConnected();
        break;
      }
      case Ue5WsDisconnected: {
        handleUe5WsDisconnected();
        break;
      }

      case FirstPersonViewRegion: {
        handleFirstPersonViewRegion();

        break;
      }

      case FreePersonViewRegion: {
        handleFreePersonViewRegion();
        break;
      }

      case MuteConference: {
        if (!conferenceId || !mutedBy) {
          return;
        }

        handleMuteConference({
          conferenceId,
          mutedBy,
        });
        break;
      }

      case StopDolbyScreenSharing: {
        handleStopDolbySharing(mediaStreamId as string);
        break;
      }

      case GamecastSessionTerminated: {
        navigateToEventsPage('notifications.duplicateGamecastSession');
        break;
      }

      default:
        break;
    }
  }, [
    lastJsonMessage,
    shareScreenPublishers,
    handleChangeParticipantProfile,
    handleJoinedToRoom,
    handleMessageToWebapp,
    handleSendToBriefcase,
    handleShowParticipantInfo,
    handleUe5WsConnected,
    handleUe5WsDisconnected,
    handleFirstPersonViewRegion,
    handleFreePersonViewRegion,
    setRoomDolbySpatialAudioScale,
    setWebsocketConnectionInfo,
    trackPoiClick,
    setParticipantState,
    handleMuteConference,
    shareVideoPublishers,
    navigateToEventsPage,
  ]);

  // Dolby WS handlers
  useEffect(() => {
    if (
      !lastJsonMessage ||
      !currentEvent ||
      currentEvent.eventId === '6f941d5c-b6db-4f4b-b79b-8b4756f305bc'
    ) {
      return;
    }

    handleDolbyWSMessages(lastJsonMessage);
  }, [lastJsonMessage, handleDolbyWSMessages, currentEvent]);
};

export const useHandleEventWebsocketMsg = (
  lastJsonMessage: LastWebsocketJsonMessageType,
) => {
  const { participants, setParticipantState } = useMytaverseEvent();

  const handleJoinedToEvent = useCallback(
    async ({ participantId, eventId }: IWebSocketConnectionInfo) => {
      //stopCommunicationFromUeTimer();
      setParticipantState(participantId, {
        eventId,
        roomId: null,
        region: null,
      });
    },
    [participants, setParticipantState],
  );

  const handleLeftToEvent = useCallback(
    (participantId = '') => {
      if (!participantId) {
        return;
      }

      setParticipantState(participantId, {
        eventId: null,
        roomId: null,
        region: null,
      });
    },
    [setParticipantState],
  );

  useEffect(() => {
    if (!lastJsonMessage) {
      return;
    }

    const { action, data, participantId = '' } = lastJsonMessage;

    switch (action) {
      case JoinedToEvent: {
        handleJoinedToEvent(data);
        break;
      }
      case LeftEvent: {
        handleLeftToEvent(participantId);
        break;
      }
      default:
        break;
    }
  }, [lastJsonMessage, handleJoinedToEvent, handleLeftToEvent]);
};

export const useHandleMuteParticipantWebsocketMsg = (
  lastJsonMessage: LastWebsocketJsonMessageType,
) => {
  const [showMutedByAdminNotification, setShowMutedByAdminNotification] =
    useState(false);

  const { setMuted } = useMytaverseEvent();
  const { showNotification, getSuccessNotification } = useNotification();
  const { t: translate } = useTranslation('common');

  const handleMuteParticipant = useCallback(
    (muted: boolean) => {
      if (showMutedByAdminNotification) {
        return;
      }

      setMuted(muted);
      setShowMutedByAdminNotification(true);
      showNotification(
        getSuccessNotification({
          title: translate('notifications.mutedByModerator.title'),
          message: translate(
            `notifications.mutedByModerator.${muted ? 'muted' : 'unmuted'}`,
          ),
          onClose: () => setShowMutedByAdminNotification(false),
        }),
      );
    },
    [
      getSuccessNotification,
      setMuted,
      showMutedByAdminNotification,
      showNotification,
      translate,
    ],
  );

  useEffect(() => {
    if (!lastJsonMessage) {
      return;
    }

    const { action, data } = lastJsonMessage;

    if (action === MuteParticipant) {
      handleMuteParticipant(data?.muted || true);
    }
  }, [handleMuteParticipant, lastJsonMessage]);
};

export const useHandleBrowserUrlRequestWebsocketMsg = (
  lastJsonMessage: LastWebsocketJsonMessageType,
) => {
  const [openBrowserUrlDialog, setOpenBrowserUrlDialog] = useState(false);

  useEffect(() => {
    if (!lastJsonMessage) {
      return;
    }

    if (lastJsonMessage.action === BrowserUrlRequest) {
      setOpenBrowserUrlDialog(true);
    }
  }, [lastJsonMessage]);

  return {
    openBrowserUrlDialog,
    setOpenBrowserUrlDialog,
  };
};

export const useHandleJoinedToRegionWebsocketMsg = (
  lastJsonMessage: LastWebsocketJsonMessageType,
) => {
  const { currentParticipantId, setParticipantState } = useMytaverseEvent();

  useEffect(() => {
    if (!lastJsonMessage) {
      return;
    }

    const { action, timestamp, region, participantId } = lastJsonMessage;

    if (!region || !participantId || currentParticipantId === participantId) {
      return;
    }

    switch (action) {
      case JoinedToRegion:
        setParticipantState(participantId, {
          region: {
            timestamp: timestamp ? parseInt(timestamp, 10) : Date.now(),
            region,
            state: 'joined',
          },
        });
        break;

      case LeftRegion:
        setParticipantState(participantId, {
          region: null,
        });
        break;

      default:
        break;
    }
  }, [lastJsonMessage, currentParticipantId, setParticipantState]);
};

export const useHandleFollowMeWebsocketMsg = (
  lastJsonMessage: LastWebsocketJsonMessageType,
) => {
  const { showNotification } = useNotification();
  const navigate = useNavigate();

  const {
    currentEvent,
    currentParticipant,
    setPendingFollowersData,
    setAcceptedFollowersData,
  } = useMytaverseEvent();
  const { sendMessageToEvent } = useMytaverse();
  const [play] = useSound(FollowMeSound, {
    volume: 0.15,
  });

  useEffect(() => {
    if (!lastJsonMessage || !currentEvent?.eventId) {
      return;
    }

    const {
      action,
      followerId = '',
      ownerId = '',
      isEventMessage = false,
      message = '',
    } = lastJsonMessage;

    const followerData: IFollowerData = {
      userId: followerId,
      adminId: ownerId,
    };

    const redirectedActions = [
      AcceptFollowing,
      DisengageFollowing,
      StopFollowing,
      InviteFollowingByAdmin,
    ];

    const redirectMessageToEvent = (
      message: UnknownObjectType<string, string>,
    ) => {
      sendMessageToEvent(currentEvent?.eventId || '', {
        ...message,
        isEventMessage: true,
      });
    };

    const handleMessage = async () => {
      if (redirectedActions.includes(action) && !isEventMessage) {
        redirectMessageToEvent({
          action,
          followerId,
          ownerId,
        });
        return;
      }

      switch (action) {
        case FollowPlayer: {
          if (followerData.userId === currentParticipant?.participantId) {
            play();
          }

          // all api updates will be done from adminId
          if (followerData.adminId === currentParticipant?.participantId) {
            await EventsService.sendFollowingInvitation({
              eventId: currentEvent?.eventId,
              participantId: followerData.userId,
              groupLeadId: followerData.adminId,
            });
            await EventsService.acceptFollowingInvitation({
              eventId: currentEvent?.eventId,
              participantId: followerData.userId,
            });
          }

          setAcceptedFollowersData((prev) => prev.concat(followerData));
          break;
        }
        case InviteFollowingByAdmin: {
          if (followerData.userId === currentParticipant?.participantId) {
            play();
          }

          // all api updates will be done from adminId
          if (followerData.adminId === currentParticipant?.participantId) {
            await EventsService.sendFollowingInvitation({
              eventId: currentEvent?.eventId,
              participantId: followerData.userId,
              groupLeadId: followerData.adminId,
            });
          }

          setPendingFollowersData((prev) => prev.concat(followerData));
          break;
        }
        case StopFollowingByAdmin:
        case StopFollowing: {
          // all api updates will be done from adminId
          if (followerData.adminId === currentParticipant?.participantId) {
            await EventsService.declineFollowingInvitation({
              eventId: currentEvent?.eventId,
              participantId: followerData.userId,
            });
          }

          setAcceptedFollowersData((prev) =>
            prev.filter(
              (data) =>
                !(
                  data.userId === followerData.userId &&
                  data.adminId === followerData.adminId
                ),
            ),
          );
          break;
        }
        case CancelInviteFollowingByAdmin:
        case DisengageFollowing: {
          // all api updates will be done from adminId
          if (followerData.adminId === currentParticipant?.participantId) {
            await EventsService.declineFollowingInvitation({
              eventId: currentEvent?.eventId,
              participantId: followerData.userId,
            });
          }

          setPendingFollowersData((prev) =>
            prev.filter(
              (data) =>
                data.userId !== followerData.userId &&
                data.adminId !== followerData.adminId,
            ),
          );
          break;
        }
        case AcceptFollowing: {
          // all api updates will be done from adminId
          if (followerData.adminId === currentParticipant?.participantId) {
            await EventsService.acceptFollowingInvitation({
              eventId: currentEvent?.eventId,
              participantId: followerData.userId,
            });
          }

          setPendingFollowersData((prev) =>
            prev.filter(
              (data) =>
                data.userId !== followerData.userId &&
                data.adminId !== followerData.adminId,
            ),
          );
          setAcceptedFollowersData((prev) => prev.concat(followerData));
          break;
        }
        case ShowNotification: {
          showNotification(
            getErrorNotification({
              message: message,
              withReloadButton: true,
              closeManually: true,
            }),
          );
          break;
        }
        case ShowDemoNotification: {
          showNotification({
            message,
            type: NOTIFICATION_TYPES.WARNING,
            closeManually: true,
            withDismissButton: false,
          });
          break;
        }
        case TerminateSession: {
          navigate(ROUTES.LOGIN);
          break;
        }
      }
    };

    handleMessage();
  }, [
    lastJsonMessage,
    currentParticipant?.participantId,
    currentEvent?.eventId,
    setPendingFollowersData,
    play,
    setAcceptedFollowersData,
    sendMessageToEvent,
  ]);
};

export const useHandleTeleportingModalWebsocketMsg = (
  lastJsonMessage: LastWebsocketJsonMessageType,
) => {
  const { setIsTeleportingToRoomByUnreal } = useMytaverseEvent();

  useEffect(() => {
    if (!lastJsonMessage) {
      return;
    }

    const { action } = lastJsonMessage;

    const handleMessage = async () => {
      switch (action) {
        case OpenTeleportModal: {
          MytaverseLogger.log('Open teleport modal');
          setIsTeleportingToRoomByUnreal(true);
          break;
        }
        case CloseTeleportModal: {
          MytaverseLogger.log('Close teleport modal');
          setIsTeleportingToRoomByUnreal(false);
          break;
        }
      }
    };

    handleMessage();
  }, [lastJsonMessage, setIsTeleportingToRoomByUnreal]);
};

export const useHandleAvatarWebsocketMsg = (
  lastJsonMessage: LastWebsocketJsonMessageType,
) => {
  const timerIdRef = useRef<NodeJS.Timer | null>(null);
  const { currentSkin, currentEvent } = useMytaverseEvent();
  const { sendMessageToUnreal, customAvatarUrl } = useMytaverse();

  const avatarMessage = useMemo(() => {
    const currentAvatarType = currentSkin
      ? currentEvent?.avatars.find(
          (avatar) => avatar.avatarId === currentSkin.avatarId,
        )?.type
      : '';

    return {
      action: AvatarSelect,
      avatarId: currentSkin ? currentSkin.avatarId : '',
      type: currentAvatarType,
      skinId: currentSkin ? currentSkin.avatarSkinId : '',
      downloadAvatarUrl: customAvatarUrl || '',
      avatarType: customAvatarUrl ? 'AVATURN' : '',
    };
  }, [currentSkin, currentEvent, customAvatarUrl]);

  const sendAvatarMessage = useCallback(() => {
    sendMessageToUnreal(avatarMessage);
    MytaverseLogger.log(`SELECT_AVATAR: ${JSON.stringify(avatarMessage)}`);
  }, [sendMessageToUnreal, avatarMessage]);

  useEffect(() => {
    if (!lastJsonMessage) {
      return;
    }

    const { action } = lastJsonMessage;

    const handleMessage = () => {
      switch (action) {
        case AvatarRequested: {
          if (!timerIdRef.current) {
            timerIdRef.current = setInterval(sendAvatarMessage, 1000);
          }

          break;
        }
        case AvatarReceived: {
          if (timerIdRef.current) {
            clearInterval(timerIdRef.current);
            timerIdRef.current = null;
          }
          break;
        }
      }
    };

    handleMessage();
  }, [lastJsonMessage, sendAvatarMessage]);

  useEffect(() => {
    return () => {
      if (timerIdRef.current) {
        clearInterval(timerIdRef.current);
        timerIdRef.current = null;
      }
    };
  }, []);
};

export const useHandleUELogWebsocketMsg = (
  lastJsonMessage: LastWebsocketJsonMessageType,
) => {
  useEffect(() => {
    if (!lastJsonMessage) {
      return;
    }

    const { action, data } = lastJsonMessage;

    if (!action?.startsWith('UE_')) {
      return;
    }

    const msg = `UE: ${data?.category} - ${JSON.stringify(data?.msg || '')}`;

    switch (action) {
      case UEError:
        MytaverseLogger.error(msg);
        break;
      case UEWarn:
        MytaverseLogger.warn(msg);
        break;
      case UEInfo:
        MytaverseLogger.info(msg);
        break;
      case UEDebug:
        MytaverseLogger.debug(msg);
        break;
      default:
        MytaverseLogger.info(msg);
        break;
    }
  }, [lastJsonMessage]);
};
