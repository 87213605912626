import axios from 'axios';
import { nanoid } from 'nanoid';

const { REACT_APP_SPATIAL_MANAGER_API_URL } = process.env;

import { IParticipant, ParticipantState } from '../interfaces/participants';
import {
  IProfile,
  ITokens,
  IUpdateParticipantData,
  IProfileState,
} from '../interfaces/profile';
import { IParticipantAvatar, IParticipantEvents } from '../interfaces/event';

export default class ParticipantsService {
  static getParticipantRoom(participantId: string): Promise<{
    participantId: string;
    roomId: string;
  }> {
    return axios
      .get(
        `${REACT_APP_SPATIAL_MANAGER_API_URL}api/v2/participants/${participantId}`,
      )
      .then((res) => res.data);
  }

  static getEmptyParticipant(participantId: string): IParticipant {
    return {
      participantId,
      streamChatMemberId: participantId.replace('|', '_'),
      key: nanoid(10),
      email: '',
      name: '',
      firstName: '',
      lastName: '',
      fullName: '',
      avatarString: '',
      avatarImage: '',
      speaking: false,
      eventId: null,
      gameSessionId: null,
      roomId: null,
      region: null,
      isSpeaker: false,
      regions: [],
      state: ParticipantState.Loading,
      avaturnId: '',
      customAvatarUrl: '',
    };
  }

  static getTokens(): Promise<ITokens> {
    return axios
      .get(`${REACT_APP_SPATIAL_MANAGER_API_URL}api/v1/profile/tokens`)
      .then((res) => res.data);
  }

  static getCurrentParticipantProfile(): Promise<IProfile> {
    return axios
      .get(`${REACT_APP_SPATIAL_MANAGER_API_URL}api/v1/profile`)
      .then((res) => res.data);
  }

  static async updateCurrentParticipantAvatar(
    data: IUpdateParticipantData,
  ): Promise<IProfile> {
    const response = await axios.patch(
      `${REACT_APP_SPATIAL_MANAGER_API_URL}api/v1/profile/avatar`,
      data,
    );

    return response.data;
  }

  static getCurrentParticipantAvatar(): Promise<IParticipantAvatar> {
    return axios
      .get(`${REACT_APP_SPATIAL_MANAGER_API_URL}api/v1/profile/avatar`)
      .then((res) => res.data);
  }

  static updateCurrentParticipantProfileTC(
    data: IUpdateParticipantData,
  ): Promise<IProfile> {
    return axios.patch(
      `${REACT_APP_SPATIAL_MANAGER_API_URL}api/v1/profile/tcs`,
      data,
    );
  }

  static updateCurrentParticipantProfileState(
    data: IProfileState,
  ): Promise<IProfile> {
    return axios.patch(
      `${REACT_APP_SPATIAL_MANAGER_API_URL}api/v1/profile/state`,
      data,
    );
  }

  static getCurrentParticipantEvents(): Promise<IParticipantEvents> {
    return axios
      .get(`${REACT_APP_SPATIAL_MANAGER_API_URL}api/v1/profile/events`)
      .then((res) => res.data);
  }
}
