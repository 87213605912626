import React, { useContext, useEffect, useState, useCallback } from 'react';

import { useNotificationContext } from '../../../providers/NotificationProvider';

import { IEventContext, EventProviderProps } from './interfaces';
import { AVATAR_SCREENS } from './constants';
import { useMytaverse } from '../../../providers/MytaverseProvider';
import ParticipantsService from '../../../services/ParticipantsService';
import { IUser } from '../../../interfaces/user';

export const EventContext = React.createContext<IEventContext>(
  {} as IEventContext,
);

export const useEventContext = () => useContext(EventContext);

const getInitAvatarScreen = (
  isTerm: boolean | null,
  avatarUrl: string | null,
) => {
  if (isTerm === null) {
    return AVATAR_SCREENS.BEGIN;
  }

  if (isTerm) {
    return avatarUrl ? AVATAR_SCREENS.CUSTOMISE : AVATAR_SCREENS.BEGIN;
  }

  return avatarUrl ? AVATAR_SCREENS.CUSTOMISE : AVATAR_SCREENS.CUSTOM_UI;
};

export const EventProvider: React.FC<EventProviderProps> = ({ children }) => {
  const { user, setUser } = useMytaverse();
  const [avatarScreen, setAvatarScreen] = useState<AVATAR_SCREENS>(
    getInitAvatarScreen(
      user?.isAvatarTermsAccepted,
      user?.customAvatarUrl || null,
    ),
  );
  const [avatarConsentOpen, setAvatarConsentOpen] = useState(false);

  const [isEventRun, setIsEventRun] = useState(false);

  const { setIsEventRun: setIsEnterWorld } = useNotificationContext();

  const updateProfileState = useCallback(async () => {
    try {
      const { state } =
        await ParticipantsService.updateCurrentParticipantProfileState({
          deviceCheckedTimestamp: Date.now(),
        });

      if (state) {
        setUser((prevState: IUser) => ({ ...prevState, state }));
      }
    } catch (e) {
      console.log('Can not update participant state');
    }
  }, [setUser]);

  useEffect(() => {
    setIsEnterWorld(isEventRun);
  }, [isEventRun]);

  useEffect(() => {
    if (isEventRun && user?.id && !user?.state?.deviceCheckedTimestamp) {
      updateProfileState();
    }
  }, [
    isEventRun,
    user?.id,
    user?.state?.deviceCheckedTimestamp,
    updateProfileState,
  ]);

  return (
    <EventContext.Provider
      value={{
        avatarScreen,
        setAvatarScreen,
        isEventRun,
        setIsEventRun,
        avatarConsentOpen,
        setAvatarConsentOpen,
      }}
    >
      {children}
    </EventContext.Provider>
  );
};
