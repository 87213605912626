import React from 'react';
import { withTranslation } from 'react-i18next';
import { Box, Button, SxProps, Typography } from '@mui/material';

import { ReactComponent as ArrowForwardIcon } from '../../../../../public/images/ek/next.svg';

import CircularProgress from '../CircularProgress';

import { IDevicesTestWrapperView } from './interfaces';
import { useStyles } from './styles';

const DevicesTestWrapperView = ({
  children,
  title,
  description,
  onNextStep,
  activeStep,
  isLastStep,
  totalSteps,
  disabled,
  isFirstJoin,
  sx = {},
  t: translate,
}: IDevicesTestWrapperView) => {
  const classes = useStyles();
  const rootSx = { ...classes.root, ...sx } as SxProps;

  return (
    <Box sx={rootSx}>
      <Box sx={classes.progressTop}>
        <CircularProgress value={activeStep} total={totalSteps} />
      </Box>
      <Box sx={classes.content}>
        <Typography sx={classes.title}>{title}</Typography>
        <Typography sx={classes.description}>{description}</Typography>
        {children}
      </Box>
      <Box sx={classes.footer}>
        <Box sx={classes.progressBottom}>
          <CircularProgress value={activeStep} total={totalSteps} />
        </Box>
        <Box sx={classes.box}>
          <Button
            sx={classes.nextBtn}
            variant="contained"
            color="primary"
            disableRipple
            disabled={disabled}
            onClick={onNextStep}
          >
            {translate(
              isLastStep ? 'devicesTest.continue' : 'devicesTest.next',
            )}{' '}
            <ArrowForwardIcon />
          </Button>
        </Box>
        {!isLastStep && !isFirstJoin && (
          <Box sx={classes.box}>
            <Button sx={classes.skipBtn} disableRipple onClick={onNextStep}>
              {translate('devicesTest.skip')}
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default withTranslation('common')(DevicesTestWrapperView);
