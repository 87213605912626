import React, { useState, useEffect } from 'react';
import { Box, Button, TextField } from '@mui/material';
import { withTranslation, WithTranslation } from 'react-i18next';

import { usePocAi } from '../../providers/PocAiProvider';
import { POC_SCREENS, enableDebugControls } from '../../constants';

import { useStyles } from './styles';

const DefaultScreen = ({ t: translate }: WithTranslation) => {
  const classes = useStyles();

  const [inputValue, setInputValue] = useState('');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const {
    navigate,
    setOpen,
    setNavigationHistory,
    queryBedrockTest,
    generateAudioPlaybackTest,
    generateGreetingsAudio,
    stopPlayback,
    startRecording,
    stopRecording,
    isRecording,
    setIsRecording,
    audioPromptHistory,
    testKeyboard,
    teleportToRegion,
    sendWSMessage,
  } = usePocAi();

  const handleRecording = () => {
    console.warn('start/stop recording button clicked');
    if (isRecording) {
      stopRecording();
    } else {
      startRecording();
    }

    setIsRecording(!isRecording);
  };

  const handleTestKeystroke = () => {
    testKeyboard();
  };

  const handleTestTeleport = () => {
    teleportToRegion('auditorium');
  };

  const handleSendWS = () => {
    console.warn('handleSendWS', inputValue);
    sendWSMessage(inputValue);
  };

  useEffect(() => {
    console.log('audioPromptHistory', audioPromptHistory);
    console.log(
      'new item in audioPromptHistory',
      audioPromptHistory[audioPromptHistory.length - 1],
    );
  }, [audioPromptHistory]);

  return (
    <Box sx={classes.box}>
      <Button sx={classes.button} onClick={() => navigate(POC_SCREENS.HOW_TO)}>
        {translate('poc.howTo.title')}
      </Button>
      <Button
        sx={{ ...classes.button, ...classes.blueButton }}
        onClick={() => navigate(POC_SCREENS.CHAT)}
      >
        {translate('poc.askMe.title')}
      </Button>
      <Button sx={classes.button} onClick={() => handleRecording()}>
        {!isRecording ? 'Voice action' : 'Stop recording'}
      </Button>
      <Button
        sx={classes.hideButton}
        onClick={() => {
          stopPlayback();
          setOpen(false);
          setNavigationHistory([]);
          navigate(POC_SCREENS.DEFAULT);
        }}
      >
        {translate('poc.hideNoa')}
      </Button>

      {enableDebugControls && (
        <>
          <Button
            sx={{ ...classes.button, ...classes.blueButton }}
            onClick={() => queryBedrockTest()}
          >
            Bedrock test
          </Button>
          <Button
            sx={{ ...classes.button, ...classes.blueButton }}
            onClick={() => generateAudioPlaybackTest()}
          >
            Audio test
          </Button>
          <Button
            sx={{ ...classes.button, ...classes.blueButton }}
            onClick={() => generateGreetingsAudio()}
          >
            Greetings test
          </Button>
          <Button
            sx={{ ...classes.button, ...classes.blueButton }}
            onClick={() => stopPlayback()}
          >
            Stop playback test
          </Button>
          <Button sx={classes.button} onClick={() => stopPlayback()}>
            Stop audio
          </Button>
          <Button sx={classes.button} onClick={() => handleTestKeystroke()}>
            Keystroke test
          </Button>
          <Button sx={classes.button} onClick={() => handleTestTeleport()}>
            Teleport test
          </Button>
          <Button
            sx={classes.button}
            onClick={() => {
              navigate(POC_SCREENS.TELEPORT);
            }}
          >
            Teleport screen
          </Button>
          <TextField
            sx={classes.box}
            placeholder="WS str json here"
            style={{ background: '#fff' }}
            variant="standard"
            value={inputValue}
            onChange={handleChange}
          />
          <Button sx={classes.button} onClick={() => handleSendWS()}>
            Send WS message
          </Button>
        </>
      )}
    </Box>
  );
};

export default withTranslation('common')(DefaultScreen);
