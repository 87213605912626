import React from 'react';
import { Divider, Typography } from '@mui/material';
import { WithTranslation, withTranslation } from 'react-i18next';

import { POC_SCREENS } from '../../constants';
import { usePocAi } from '../../providers/PocAiProvider';
import ModalBox from '../../ModalBox';
import PocForm from '../../PocForm';
import ChatBox from '../../ChatBox';
import { useStyles } from './styles';

const ChatScreen = ({ t: translate }: WithTranslation) => {
  const classes = useStyles();

  const { setOpen, setNavigationHistory, navigate, stopPlayback } = usePocAi();

  const renderTitle = () => {
    return (
      <Typography sx={classes.title}>{translate('poc.askMe.askMe')}</Typography>
    );
  };

  return (
    <ModalBox
      title={renderTitle()}
      onClose={() => {
        stopPlayback();
        setOpen(false);
        setNavigationHistory([]);
        navigate(POC_SCREENS.DEFAULT);
      }}
      onBack={() => {
        navigate(POC_SCREENS.DEFAULT, true);
      }}
    >
      <Typography sx={classes.tabTitle}>
        {translate('poc.askMe.currentChat')}
      </Typography>
      <ChatBox />
      <Divider sx={classes.divider} />
      <PocForm />
    </ModalBox>
  );
};

export default withTranslation('common')(ChatScreen);
