import React from 'react';
import { useNavigate } from 'react-router-dom';

import DevicesTestWrapperView from './DevicesTestWrapperView';

import { useMytaverseEvent } from '../../../../../modules/dashboard/providers';
import { useDevicesTestState } from '../../../../../modules/auth/pages/DevicesTestPage/hooks/state';

import ROUTES from '../../../../../constants/routes';

import { IDevicesTestWrapper } from './interfaces';
import { useMytaverse } from '../../../../../providers/MytaverseProvider';

const DevicesTestWrapper = ({
  children,
  title,
  description,
  disabled,
  sx,
}: IDevicesTestWrapper) => {
  const { currentEventId, currentEvent } = useMytaverseEvent();
  const { user } = useMytaverse();
  const { step, setStep } = useDevicesTestState();
  const navigate = useNavigate();

  // const isLastStep = step === DEVICES_TEST_STEPS_COUNT;
  const [isLastStep, totalSteps] = React.useMemo(() => {
    const defaultRoom = currentEvent?.rooms.find((room) => room.isDefault);

    if (defaultRoom && !defaultRoom.isMultiPlayer) {
      return [step === 2, 2];
    }

    return [step === 4, 4];
  }, [step, currentEvent]);

  const handleNextStep = () => {
    if (isLastStep) {
      navigate(ROUTES.EVENT_PAGE(currentEventId));
    } else {
      setStep(step + 1);
    }
  };

  return (
    <DevicesTestWrapperView
      title={title}
      description={description}
      onNextStep={handleNextStep}
      activeStep={step}
      isLastStep={isLastStep}
      totalSteps={totalSteps}
      disabled={disabled}
      isFirstJoin={!user?.state?.deviceCheckedTimestamp}
      sx={sx}
    >
      {children}
    </DevicesTestWrapperView>
  );
};

export default DevicesTestWrapper;
