import { IAvaturnTokens } from './avaturn';
import { WsConnectionClientType } from './index';

export interface IWsConnection {
  participantId: string;
  sessionId: string;
  connectionId: string;
  loginToken: string;
  clientType: WsConnectionClientType;
}

export interface IUserLastJoinEvents {
  eventId: string;
  lastJoinTime: number;
}

export interface IProfileState {
  deviceCheckedTimestamp?: number;
}

export interface IProfile {
  id: string;
  wsUrl: string;
  wsConnections: IWsConnection[];
  events: IUserLastJoinEvents[];
  hrData: IiXRHrData;
  avaturnId?: string;
  customAvatarUrl?: string;
  state?: IProfileState | null;
}

export enum IXRHrBrandTypes {
  Mytaverse = 'MYTAVERSE',
  Dnata = 'DNATA', // Uniformed
  Marhaba = 'MARHABA', // Uniformed
  Ekas = 'EMIRATES - EKAS', // Uniformed
  CC = 'EMIRATES - CONTACT CENTRE', // Non Uniformed
  HR = 'HR FACILITATOR', // Non Uniformed
}

export const IXR_HR_BRAND_UNIFORMS = ['DNATA', 'MARHABA', 'EMIRATES - EKAS'];

export enum IXRHrCredStatusTypes {
  Pre = 'ADVISE TO RESIGN',
  FT = 'FULL TIME EMPLOYEE',
}

export enum IXRHrGenderTypes {
  M = 'M',
  F = 'F',
}

export enum IXRHrPersonaTypes {
  CSA = 'CSA',
  HR_Facilitator = 'HR FACILITATOR',
}

export interface IiXRHrData {
  staffId: string;
  batchId: number;
  brand: string;
  ekCreatedDate: string;
  ekUpdateDate: string;
  gender: string;
  hrFacilitatorStaffId: string;
  ixrCredentialStatus: string;
  jobTitle: string;
  joiningDate: string;
  localHire: boolean;
  name: string;
  nickName: string;
  persona: string;
  personalEmail: string;
  preferredName: string;
  residingCountry: string;
}

export interface IUpdateParticipantData {
  isTermsAccepted?: boolean;
  isAvatarTermsAccepted?: boolean | null;
  avaturnId?: string;
  customAvatarUrl?: string;
}

export interface ITokens {
  avaturn: IAvaturnTokens;
  streamChat: string;
}
