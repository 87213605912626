import React from 'react';
import { Box, Typography } from '@mui/material';

import ModalBox from '../../ModalBox';

import { useStyles } from './styles';
import { usePocAi } from '../../providers/PocAiProvider';
import { POC_SCREENS, teleportPlaces } from '../../constants';
import TeleportBox from '../../TeleportBox';
import PocTag from '../../PocTag';
import { withTranslation, WithTranslation } from 'react-i18next';

const HowToScreen = ({ t: translate }: WithTranslation) => {
  const classes = useStyles();

  const { setOpen, navigate, setNavigationHistory, stopPlayback } = usePocAi();

  const renderTitle = () => {
    return (
      <Box sx={classes.titleBox}>
        <Typography sx={classes.title}>Noa</Typography>
        <PocTag />
      </Box>
    );
  };

  const callback = (val: string) => {
    console.warn('callback', val);
  };

  return (
    <ModalBox
      title={renderTitle()}
      onClose={() => {
        stopPlayback();
        setOpen(false);
        setNavigationHistory([]);
        navigate(POC_SCREENS.DEFAULT);
      }}
      onBack={() => {
        navigate(POC_SCREENS.DEFAULT, true);
      }}
    >
      <Typography sx={classes.text}>Teleport to</Typography>

      <Box sx={classes.questions}>
        {teleportPlaces.map((title, index) => (
          <TeleportBox key={index} title={title.replace('second', '2nd').replace('third', '3rd')} />
        ))}
      </Box>
    </ModalBox>
  );
};

export default withTranslation('common')(HowToScreen);
